<template>
  <main class="main">
    <!-- <nav class="breadcrumb-nav border-0 mb-0">
            <div class="container">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">Home</router-link>
                    </li>
                    <li class="breadcrumb-item active">404</li>
                </ol>
            </div>
        </nav> -->

    <!-- <div class="error-content text-center" :style="{ 'background-image': `url(${img})` }" -->
    <div class="error-content">
      <div class="container">
        <div class="row">
          <div class="col-md-12 bg-white d-flex flex-column align-items-center">
            <!-- <h1 class="error-title">{{ $t('404_1') }}</h1> -->
            <h3>{{ $t("404_1") }}</h3>
            <img
              src="@/esf_weert_mobilesupplies/assets/static/images/404.png"
              class="my-5"
              alt="404"
            />
            <h4>{{ $t("404_2") }}</h4>
            <!-- <router-link to="/" class="btn btn-outline-primary-2 btn-minwidth-lg">
                            <i class="icon-long-arrow-left"></i>
                            <span>BACK TO HOMEPAGE</span>
                        </router-link> -->
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      img: require("@/esf_weert_mobilesupplies/assets/static/images/error-bg.jpg"),
    };
  },
  metaInfo() {
    return {
      title: "404 Page Not Found",
      meta: [
        {
          name: "description",
          content: "404 Page Not Found",
        },
      ],
    };
  },
};
</script>
